interface RedirectByLoginUrL{
    loginUrl: string;
    loginUrlMatch: string;
    to: string;
    payload:{
        [key: string]: string;
    }
}

export const redirectByLoginUrl = (obj: RedirectByLoginUrL[]) => {
    for( const item of obj ){
        const { loginUrl, loginUrlMatch, to, payload } = item;
        
        if(loginUrl === loginUrlMatch){
            const queryParams = Object.entries(payload).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');
            // console.log( queryParams );
            window.location.href = `${to}?${queryParams}`;
            break;
        }
    }
}