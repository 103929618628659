import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth.store';
import type { AxiosError } from 'axios';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/AllMonumentsView.vue')
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/LoginView.vue')
    },
    {
      path: '/login-invitados',
      name: 'loginInvitados',
      component: () => import('../views/LoginView.vue')
    },
    {
      path: '/home',
      name: 'AllMonuments',
      component: () => import('../views/AllMonumentsView.vue')
    },
    {
      path: '/buscador-reservas',
      name: 'BuscadorReservas',
      component: () => import('../views/Bookings/WebBookings.vue')
    },
    {
      path: '/localizadores',
      name: "Localizadores",
      component: () => import("../views/Bookings/LocalizadorView.vue")
    },
    {
      path: '/informe-visitas',
      name: 'InformeDeVisitas',
      component: () => import("../views/Bookings/VisitsReportsView.vue")
    },
    {
      path: '/usuarios',
      name: 'Usuarios',
      component: () => import('../views/Users/UsersAdminPanel.vue')
    },
    // Ruta tempra para las audioguias
    {
      path: '/audioguides/:type',
      name: 'AudioguidesHome',
      component: () => import('../views/Audioguias/AudioguiasHomeView.vue')
    },
    {
      path: '/administrar-monumento/:monumento/audioguides/:type',
      name: 'Audioguias',
      component: () => import('../views/Audioguias/AudioguiasSearchView.vue')
    },
    {
      path: '/administrar-monumento/:monumento/audioguides/audio-tracks/:action/',
      name: 'AudioTracksCreator',
      component: () => import('../views/Audioguias/AudioView.vue')
    },
    {
      path: '/administrar-monumento/:monumento/audioguides/audio-guided-tours/:action/',
      name: 'AudioguiaCreator',
      component: () => import('../views/Audioguias/AudioguideView.vue')
    },
    // {
    //   path: '/administrar-monumento/:monumento/audioguides/:type/:id/:action',
    //   name: 'AudioguiaEditor',
    //   component: () => import('../views/Audioguias/AudioguiaManager.vue')
    // },
    /**
     * Rutas relacionadas con los monumentos
     * */
    {
      path: '/guidum',
      name: 'Guidum',
      component: () => import('../views/Guidum/GuidumView.vue')
    },
    {
      path: '/Guidum/prices',
      name: 'GuidumPrices',
      component: () => import('../views/Guidum/GuidumPricesView.vue')
    },
    {
      path: '/alta-monumento',
      name: 'AltaMonumento',
      component: () => import('../views/NewMonument.vue')
    },
    //prestadores de servicios
    {
      path: '/login-prestadores',
      name: 'LoginPrestadores',
      component: () => import('../views/Prestadores/PrestadoresLogin.vue')
    },
    {
      path: '/ficha-prestador',
      meta: { breadcrumb: [{ name: 'Ficha Promotor', path: '/ficha-prestador' }] },
      name: 'FichaPrestador',
      component: () => import('../views/Prestadores/FichaPrestador.vue')
    },
    {
      path: '/servicios-prestador/:prestadorId/:prestadorName',
      name: 'LayoutServiciosPrestador',
      component: () => import('../components/Prestadores/LayoutServiciosPrestador.vue'), // Un componente layout que agrupa las vistas relacionadas
      children: [
        {
          path: '',
          component: () => import('../views/Prestadores/ServiciosPrestador.vue'),
          name: 'ServiciosPrestador',
          meta: {
            breadcrumb: [
              { name: 'Ficha Promotor', path: '/ficha-prestador' },
              { name: 'Servicios Promotor', path: '/servicios-prestador/:prestadorId/:prestadorName' }
            ]
          }
        },
        {
          path: 'edit-servicios/:servicioId/:servicioName',
          component: () => import('../views/Prestadores/EditarServicioPrestador.vue'),
          name: 'EditarServicioPrestador',
          meta: {
            breadcrumb: [
              { name: 'Ficha Promotor', path: '/ficha-prestador' },
              { name: 'Servicios Promotor', path: '/servicios-prestador/:prestadorId/:prestadorName' },
              { name: 'Editar Servicio', path: '/servicios-prestador/:prestadorId/:prestadorName/edit-servicios/:servicioId/:servicioName' }
            ]
          }
        },
        {
          path: 'crear-servicios/',
          component: () => import('../views/Prestadores/CrearServicio/CrearServicio.vue'),
          name: 'CrearServicioPrestador',
          meta: {
            breadcrumb: [
              { name: 'Ficha Promotor', path: '/ficha-prestador' },
              { name: 'Servicios Promotor', path: '/servicios-prestador/:prestadorId/:prestadorName' },
              { name: 'Crear Servicio', path: '/servicios-prestador/:prestadorId/:prestadorName/crear-servicios/' }
            ]
          }
        },
        {
          path: 'service-images/:serviceId/:serviceName/:type',
          component: () => import('../views/Prestadores/Servicios/ImagenesServicioView.vue'),
          name: 'ServiceImages',
          meta: {
            breadcrumb: [
              { name: 'Ficha Promotor', path: '/ficha-prestador' },
              { name: 'Servicios Promotor', path: '/servicios-prestador/:prestadorId/:prestadorName' },
              { name: 'Imágenes Servicio', path: '/servicios-prestador/:prestadorId/:prestadorName/service-images/:serviceId/:serviceName' }
            ]
          }
        }
      ]
    },
    {
      path: '/paquetes-prestador/:prestadorId/:prestadorName',
      name: 'LayoutPaquetesPrestador',
      component: () => import('@/views/Prestadores/Paquetes/LayoutPaquetesPrestadorView.vue'),
      children:[
        {
          path: '',
          name: 'PaquetesPrestador',
          component: () => import('@/views/Prestadores/Paquetes/PaquetesPrestadorView.vue'),
          meta: {
            breadcrumb: [
              { name: 'Ficha Promotor', path: '/ficha-prestador' },
              { name: 'Paquetes Promotor', path: '/paquetes-prestador/:prestadorId/:prestadorName' }
            ]
          }
        },
        {
          path: 'edit-paquete/:paqueteId/:paqueteName',
          component: () => import('@/views/Prestadores/Paquetes/EditarPaqueteView.vue'),
          name: 'EditarPaquetePrestador',
          meta: {
            breadcrumb: [
              { name: 'Ficha Promotor', path: '/ficha-prestador' },
              { name: 'Tours Promotor', path: '/paquetes-prestador/:prestadorId/:prestadorName' },
              { name: 'Editar Tour', path: '/servicios-prestador/:prestadorId/:prestadorName/edit-paquete/:paqueteId/:paqueteName' }
            ]
          }
        },
        {
          path: 'crear-paquete',
          component: () => import('@/views/Prestadores/Paquetes/CrearPaqueteView.vue'),
          name: 'CrearPaquete',
          meta: {
            breadcrumb: [
              { name: 'Ficha Promotor', path: '/ficha-prestador' },
              { name: 'Servicios Promotor', path: '/paquetes-prestador/:prestadorId/:prestadorName' },
              { name: 'Crear Tour', path: '/servicios-prestador/:prestadorId/:prestadorName/crear-paquete/' }
            ]
          }
        },
        {
          path: 'configurar-paquete',
          component: () => import('@/views/Prestadores/Paquetes/PackConfigurationView.vue'),
          name: 'ConfigurarPaquete',
          meta: {
            breadcrumb: [
              { name: 'Ficha Promotor', path: '/ficha-prestador' },
              { name: 'Tour Promotor', path: '/paquetes-prestador/:prestadorId/:prestadorName' },
              { name: 'Configurar Tour', path: '/servicios-prestador/:prestadorId/:prestadorName/configurar-paquete/' }
            ]
          }
        }
      ]
    },
    {
      path: '/prestadores',
      name: 'Prestadores',
      component: () => import('../views/Prestadores/admin/LayoutPrestadorAdmin.vue'),
      children:[
        {
          path: '',
          name: 'PrestadoresList',
          component: () => import('../views/Prestadores/admin/PrestadoresView.vue'),
          meta: {
            breadcrumb: [
              { name: 'Inicio', path: '/' },
              { name: 'Promotores', path: '/prestadores' }
            ]
          }
        },
        {
          path: 'solictudes',
          name: 'SolicitudesPrestadores',
          component: () => import('../views/Prestadores/admin/SolicitudesPrestadoresView.vue'),
          meta: {
            breadcrumb: [
              { name: 'Inicio', path: '/' },
              { name: 'Promotores', path: '/prestadores' },
              { name: 'Solicitudes', path: '/prestadores/solictudes' }
            ]
          }
        }
      ]
    },
    {
      path: '/tarifas-prestador/:prestadorId/:prestadorName',
      name: 'TarifasPrestador',
      component: () => import('@/views/Prestadores/Tarifas/TarifasPrestadorView.vue'),
      meta: {
        breadcrumb: [
          { name: 'Ficha Promotor', path: '/ficha-prestador' },
          { name: 'Tarifas Promotor', path: '/tarifas-prestador/:prestadorId/:prestadorName' }
        ]
      }
    },
    {
      path: '/tarifas-prestador/:prestadorId/:prestadorName/crear-grupo',
      name: 'CrearTarifasPrestador',
      component: () => import('@/views/Prestadores/Tarifas/CrearTarifa/CrearTarifaView.vue'),
      meta: {
        breadcrumb: [
          { name: 'Ficha Promotor', path: '/ficha-prestador' },
          { name: 'Tarifas Promotor', path: '/tarifas-prestador/:prestadorId/:prestadorName' },
          { name: 'Crear Grupo De Tarifas', path: '/tarifas-prestador/:prestadorId/:prestadorName/crear-grupo' }
        ]
      }
    },
    {
      path: '/tarifas-prestador/:prestadorId/:prestadorName/editar-tarifa/:tarifaId/:tarifaName',
      name: 'EditarTarifasPrestador',
      component: () => import('@/views/Prestadores/Tarifas/EditTarifa/EditTarifaView.vue'),
      meta: {
        breadcrumb: [
          { name: 'Ficha Promotor', path: '/ficha-prestador' },
          { name: 'Tarifas Promotor', path: '/tarifas-prestador/:prestadorId/:prestadorName' },
          { name: 'Editar Grupo De Tarifas', path: '/tarifas-prestador/:prestadorId/:prestadorName/editar-tarifa/:tarifaId/:tarifaName' }
        ]
      }
    },
    {
      path: '/localizadores-operador/:prestadorId/:prestadorName',
      name: 'LocalizadoresOperador',
      component: () => import('@/views/Prestadores/LocalizadorPromotorView.vue'),
      meta: {
        breadcrumb: [
          { name: 'Ficha Promotor', path: '/ficha-prestador' },
          { name: 'Localizadores', path: '/localizadores-operador/:prestadorId/:prestadorName' },
        ]
      }
    },
    {
      path: '/reservas-operador/:prestadorId/:prestadorName',
      name: 'ReservasOperador',
      component: () => import('@/views/Prestadores/Bookings/PackBookings.vue'),
      meta: {
        breadcrumb: [
          { name: 'Ficha Promotor', path: '/ficha-prestador' },
          { name: 'Reservas', path: '/reservas-prestador/:prestadorId/:prestadorName' },
        ]
      }
    },
    {
      path: '/ficha-prestador/:prestadorId/:prestadorName/calendario',
      name: 'CalendarioPrestador',
      // component: () => import('@/views/Prestadores/Calendario/EditarCalendario.vue'),
      component: () => import('@/views/Prestadores/Calendario/CalendarioDePaquetes.vue'),
      meta: {
        breadcrumb: [
          { name: 'Ficha Promotor', path: '/ficha-prestador' },
          { name: 'Calendario Promotor', path: '/ficha-prestador/:prestadorId/:prestadorName/calendario' }
        ]
      }
    },
    {
      path: '/paquetes-prestador/:prestadorId/:prestadorName/imagenes-paquete/:type',
      name: 'ImagenesPrestador',
      component: () => import("@/views/Prestadores/Paquetes/PaquetesImagenesView.vue")
    },
    /*
      Paquetes Monumentos
    */
    {
      path: '/administrar-monumento/:monumento/paquetes',
      name: 'PaquetesMonumento',
      component: () => import('../views/MonumentServices/PaquetesMonumentoView.vue'),
      meta: {
        breadcrumb: [
          { name: 'Inicio', path: '/' },
          { name: 'configuración', path: '/administrar-monumento/:monumento' },
          { name: 'Paquetes', path: '/administrar-monumento/:monumento/paquetes'}
        ]
      }
    },
    {
      path: '/administrar-monumento/:monumento/paquetes/crear-paquete',
      name: 'CrearPaquetesMonumento',
      component: () => import('../views/MonumentServices/CrearPaqueteMonumentoView.vue'),
      meta: {
        breadcrumb: [
          { name: 'Inicio', path: '/' },
          { name: 'configuración', path: '/administrar-monumento/:monumento' },
          { name: 'Paquetes', path: '/administrar-monumento/:monumento/paquetes'},
          { name: 'Crear Paquete', path: '/administrar-monumento/:monumento/paquetes/crear-paquete'},
        ]
      }
    },
    {
      path: '/administrar-monumento/:monumento/paquetes/editar-paquete/:paqueteId/:paqueteName',
      name: 'EditarPaquetesMonumento',
      component: () => import('../views/MonumentServices/EditarPaqueteMonumentoView.vue'),
      meta: {
        breadcrumb: [
          { name: 'Inicio', path: '/' },
          { name: 'configuración', path: '/administrar-monumento/:monumento' },
          { name: 'Paquetes', path: '/administrar-monumento/:monumento/paquetes'},
          { name: 'Editar Paquete', path: '/administrar-monumento/:monumento/paquetes/editar-paquete/:paqueteId/:paqueteName'},
        ]
      }
    },
    {
      path: '/administrar-monumento/:monumento',
      name: 'monumentAdministrator',
      component: () => import('../views/MonumentAdmin.vue')
    },
    {
      path: '/administrar-monumento/:monumento/editar',
      name: 'EditarMonumento',
      component: () => import('../views/EditarMonumento.vue')
    },

    {
      path: "/administrar-monumento/:monumento/personalizar",
      name: "PersonalizarMonumento",
      component: () => import("../views/MonumentsConfiguration/CustomizationView.vue")
    },

    /**
     *  Rutas relacionadas con los productos/visitas de los monumentos
     * */
    {
      path: '/administrar-monumento/:monumento/visitas',
      name: 'VisitasDelMonumento',
      component: () => import('../views/MonumentProducts/Visits/VisitsView.vue')
    },
    {
      path: "/administrar-monumento/:monumento/visitas/crear",
      name: "RegistrarVisita",
      component: () => import("../views/MonumentProducts/Visits/NewVisit.vue")
    },
    {
      path: "/administrar-monumento/:monumento//visitas/editar",
      name: "EditarVisita",
      component: () => import("../views/MonumentProducts/Visits/EditVisit.vue")
    },
    {
      path: "/administrar-monumento/:monumento/visitas/crear-servicio",
      name: "RegistrarServcioVisita",
      component: () => import("../views/MonumentProducts/Visits/NewService.vue")
    },
    {
      path: "/administrar-monumento/:monumento/visitas/editar-servicio",
      name: "EditarServicioVisita",
      component: () => import("../views/MonumentProducts/Visits/EditServiceVisit.vue")
    },

    /**
   *  Rutas relacionadas con los productos/visitas de los monumentos
   * */
    {
      path: '/administrar-monumento/:monumento/restaurantes',
      name: 'RestaurantesDelMonumento',
      component: () => import('../views/MonumentServices/RestaurantsView.vue')
    },
    {
      path: '/administrar-monumento/:monumento/restaurantes/crear',
      name: 'CrearRestaurante',
      component: () => import('../views/MonumentServices/RecommendationForm.vue')
    },
    {
      path: '/administrar-monumento/:monumento/restaurantes/editar',
      name: 'EditarRestaurante',
      component: () => import('../views/MonumentServices/RecommendationForm.vue')
    },

    {
      path: '/administrar-monumento/:monumento/actividades',
      name: 'ActividadesDelMonumento',
      component: () => import('../views/MonumentServices/ActivitiesView.vue')
    },
    {
      path: '/administrar-monumento/:monumento/actividades/crear',
      name: 'CrearActividad',
      component: () => import('../views/MonumentServices/RecommendationForm.vue')
    },
    {
      path: '/administrar-monumento/:monumento/actividades/editar',
      name: 'EditarActividad',
      component: () => import('../views/MonumentServices/RecommendationForm.vue')
    },


    {
      path: "/administrar-monumento/:monumento/hospedajes",
      name: "HospedajesDelMonumento",
      component: () => import("../views/MonumentServices/HotelesView.vue")
    },
    {
      path: "/administrar-monumento/:monumento/hospedajes/crear",
      name: "CrearHospedaje",
      component: () => import("../views/MonumentServices/RecommendationForm.vue")
    },
    {
      path: "/administrar-monumento/:monumento/hospedajes/editar",
      name: "EditarHospedaje",
      component: () => import("../views/MonumentServices/RecommendationForm.vue")
    },

    /**
     * Tarifas de monumentos
     */
    { path: "/administrar-monumento/:monumento/tarifas",
      name: "TarifasDeMonumento",
      component: () => import("../views/MonumentsConfiguration/TarifasView.vue") },
    { path: "/administrar-monumento/:monumento/tarifas/crear",
      name: "NuevaTarifa",
      component: () => import("../views/MonumentsConfiguration/NewTarifa.vue")
    },
    { path: "/administrar-monumento/:monumento/tarifas/editar",
      name: "EditarTarifa",
      component: () => import("../views/MonumentsConfiguration/PreciosView.vue")
    },
    { path: "/administrar-monumento/:monumento/tarifas/editar/nuevo-precio",
      name: "CrearPrecio",
      component: () => import("../views/MonumentsConfiguration/NewPrice.vue")
    },
    {
      path: '/administrar-monumento/:monumento/tarifas/paquetes/crear',
      name: 'NuevaTarifaPaquete',
      component: () => import('../views/MonumentsConfiguration/NewPaqueteTarifa.vue')
    },
    {
      path: '/administrar-monumento/:monumento/tarifas/editar',
      name: 'EditarTarifa',
      component: () => import('../views/MonumentsConfiguration/PreciosView.vue')
    },
    { path: "/administrar-monumento/:monumento/tarifas/editar/editar-precio",
      name: "EditarPrecio",
      component: () => import("../views/MonumentsConfiguration/EditPrice.vue")
    },
    {
      path: '/administrar-monumento/:monumento/tarifas/paquetes/editar',
      name: 'EditarTarifaPaquete',
      component: () => import('../views/MonumentsConfiguration/PreciosView.vue')
    },
    {
      path: '/administrar-monumento/:monumento/tarifas/editar/nuevo-precio',
      name: 'CrearPrecio',
      component: () => import('../views/MonumentsConfiguration/NewPrice.vue')
    },


    {
      path: "/administrar-monumento/:monumento/tourcms-available-visits",
      name: "VistaDeVisitasTourcms",
      component: () => import("../views/MonumentsConfiguration/TourcmsView.vue")
    },
    {
      path: "/administrar-monumento/:monumento/tourcms-available-visits/edit",
      name: "TourcmsVisitSync",
      component: () => import("../views/MonumentsConfiguration/TourcmsVisitSync.vue")
    },


    /**
     * Calendario de monumentos
     */
    {
      path: "/administrar-monumento/:monumento/calendario",
      name: "CalendarioDeEventos",
      component: () => import("../views/MonumentProducts/Visits/CalendarView.vue")
    },

    {
      path: "/administrar-monumento/:monumento/configuraciones",
      name: "ConfiguracionesGenerales",
      component: () => import("../views/MonumentsConfiguration/GeneralConfiguration.vue")
    },

    {
      path: "/clientes",
      name: "Clientes",
      component: () => import("../views/Clients/ClientsView.vue")
    },
    {
      path: "/condiciones-comerciales",
      name: "CondicionesComerciales",
      component: () => import("../views/CommercialTerms/CommercialTermsView.vue")
    },
    {
      path: "/condiciones-comerciales/monumentos",
      name: "CondicionesComercialesPorMonumento",
      component: () => import("../views/CommercialTerms/MonumentsTermsView.vue")
    },
    {
      path: "/condiciones-comerciales/monumentos/visitas",
      name: "CondicionesComercialesPorVisitas",
      component: () => import("../views/CommercialTerms/VisitsTermsView.vue")
    },
    {
      path: "/condiciones-comerciales-monumentos-mayoristas",
      name: "CondicionesComercialesPorMonumentoParaMayoristas",
      component: () => import("../views/CommercialTerms/MonumentsWholesalersTermsView.vue")
    },

    {
      path: "/condiciones-comerciales-otas",
      name: "CondicionesComercialesOtas",
      component: () => import("../views/CommercialTerms/MonumentsOtasTermsView.vue")
    },

    {
      path: "/condiciones-comerciales-mayoristas",
      name: "CondicionesComercialesMayoristas",
      component: () => import("../views/CommercialTerms/WholesalerTermsView.vue")
    },
    {
      path: "/condiciones-comerciales-promotores",
      name: "CondicionesComercialesPromotores",
      component: () => import("../views/CommercialTerms/PromotoresTermsView.vue")
    },
    {
      path: "/administrar-monumento/:monumento/imagenes/:type",
      name: "Imagenes",
      component: () => import("../views/ImagenesView.vue")
    },

    {
      path: "/administrar-monumento/:monumento/taquilla",
      name: "TicketingConfig",
      component: () => import("../views/TicketingConfigView.vue")
    },

    {
      path: "/administrar-monumento/:monumento/imagenes/:type",
      name: "Imagenes",
      component: () => import("../views/ImagenesView.vue")
    },

    {
      path: "/administrar-monumento/:monumento/productos",
      name: "MonumentoProductos",
      component: () => import("../views/MonumentProducts/ProductsView.vue")
    },
    {
      path: "/administrar-monumento/:monumento/productos/formulario",
      name: "ProductsForm",
      component: () => import("../views/MonumentProducts/ProductsForm.vue")
    },

    /**
     * Mayoristas
     */
    {
      path: "/panel-mayoristas",
      name: "PanelDeMayoristas",
      component: () => import("../views/WholesalersPanel/WholesalerPanelView.vue")
    },

    {
      path: "/panel-mayoristas/detalles",
      name: "DetallesDeMayorista",
      component: () => import("../views/WholesalersPanel/WholesalerDetails.vue")
    },

    {
      path: "/panel-mayoristas/transferencias",
      name: "ConfirarTransferencias",
      component: () => import("../views/WholesalersPanel/TransferConfigurationView.vue")
    },

    {
      path: "/liquidaciones",
      name: "Liquidaciones",
      component: () => import("../views/Liquidaciones/LiquidacionesView.vue")
    },

    /**
     * Facturación
     */
    {
      path: "/facturacion",
      name: "BillingsArea",
      component: () => import("../views/Billings/BillingView.vue")
    },

    {
      path: "/solicitudes-mayoristas",
      name: "SolicitudesMayoristas",
      component: () => import("../views/Solicitudes/MayoristasView.vue")
    },

    {
      path: "/solicitudes-monumentos",
      name: "SolicitudesDeMonumentos",
      component: () => import("../views/Solicitudes/SolicitudesMonumentos.vue")
    },
    {
      path: "/solicitudes-otas",
      name: "SolicitarUnirseAOtas",
      component: () => import("../views/Solicitudes/SolicitudesOtasSpain.vue")
    },
    {
      path: "/admin/solicitudes-otas",
      name: "SolicitudesDeMonumentosParaUsarOtas",
      component: () => import("../views/Solicitudes/SolicitudesAdminOtas.vue")
    },
    {
      path:"/admin/mayoristas",
      name: "AdministracioMayoristas",
      component: () => import("../views/AdminPanel/MayoristasRegistrados.vue")
    },
    {
      path: "/admin/mayoristas/solicitud-de-cancelacion",
      name: "SolicitudDeCancelacion",
      component: () => import("../views/Solicitudes/SolicitudesDeCancelacionMayoristas.vue")
    },
    {
      path:"/admin/mayoristas/:mayoristaId/monumentos",
      name: "AdministracionMonumentosMayorista",
      component: () => import("../views/AdminPanel/MayoristasMonumentControl.vue")
    },
    {
      path: "/admin/otas",
      name: "AdministracionOtas",
      component: () => import("../views/Otas/OtasView.vue")
    },
    {
      path: "/admin/tourcms-departures",
      name: "TourcmsDepartures",
      component: () => import("../views/Otas/DeparturesView.vue")
    },
    {
      path: "/admin/historico-liquidaciones",
      name: "HistoricoDeLiquidaciones",
      component: () => import("../views/Liquidaciones/HistoricoLiquidaciones.vue")
    },
    {
      path: "/admin/modulos",
      name: "AdminModulos",
      component: () => import("../views/AdminConfig/modulosView.vue")
    },
    {
      path: "/admin/roles",
      name: "AdminRoles",
      component: () => import("../views/AdminConfig/rolesView.vue")
    },
    {
      path: "/admin/tipo-monumentos",
      name: "TiposDeMonumento",
      component: () => import("../views/AdminConfig/TiposDeMonumentos.vue")
    },
    {
      path: "/admin/tipos-servicios",
      name: "TiposDeServicios",
      component: () => import("../views/AdminConfig/TiposDeServiciosView.vue")
    },
    {
      path: "/admin/opciones-avanzadas",
      name: "AdvancedOptions",
      component: () => import("../views/AdminConfig/AdvancedOptions.vue")
    },
    {
      path: "/admin/facturacion-admin",
      name: "ConfigurarFacturacion",
      component: () => import("../views/AdminConfig/ConfiguracionDeFacturacion.vue")
    },
    {
      path: "/admin/spainhn-statistics",
      name: "SpainhnStatistics",
      component: () => import("../views/AdminPanel/SpainhnStatistics.vue")
    },
    {
      path: "/tipo-documentos",
      name: "TipoDeDocumentos",
      component: () => import("../views/Documents/DocumentsTypesView.vue")
    },
    {
      path: "/tipo-rutas",
      name: "TipoDeRutas",
      component: () => import("../views/Rutas/TiposDeRutaView.vue")
    },
    {
      path: "/rutas",
      name: "AdministradorDeRutas",
      component: () => import("../views/Rutas/RutaView.vue")
    },
    {
      path: "/documentos",
      name: "DocumentosDisponibles",
      component: () => import("../views/Documents/DocumentsView.vue")
    },

    /**Cupones */
    {
      path: "/cupones",
      name: "AdministracionDeCupones",
      component: () => import("../views/Cupones/CuponesAdmin.vue")
    },
    {
      path: "/codigos-promocionales",
      name: "AdministracionDeCodigos",
      component: () => import("../views/Cupones/PromoCodeAdmin.vue")
    },
    {
      path: "/generar-cupones",
      name: "CreacionDeCupones",
      component: () => import("../views/Cupones/CuponesCreator.vue")
    },
    {
      path: "/crear-codigos-promocionales",
      name: "PromoCodesCreator",
      component: () => import("../views/Cupones/PromoCodeCreator.vue")
    },

    {
      path: "/solicitudes-monumentos/:solicitud",
      name: "AvancesDeSolicitud",
      component: () => import("../views/Solicitudes/AvancesEnSolicitudMonumentos.vue")
    },

    /**Asientos */
    {
      path: '/administrar-monumento/:monumento/plantillas',
      name: 'PlantillasResume',
      component: () => import('../views/SeatTemplates/PlantillasResume.vue')
    },
    {
      path: '/adminsitrar-monumento/:monumento/plantilla-asientos',
      name: 'NewPlantilla',
      component: () => import('@/views/SeatTemplates/PlantillasCreator.vue')
    },
    {
      path: '/plantilla/:monumento/:plantilla',
      name: 'PlantillaEdit',
      component: () => import('../views/SeatTemplates/PlantillasView.vue')
    },
    {
      path: "/traducciones",
      name: "Traducciones",
      component: () => import("../views/Traducciones/TranslatesView.vue")
    },
    // {
    //   path: '/administrar-monumento/:monumento/:visitaId/:visitaName',
    //   name: "EditarVisita",
    //   component: () => import("../views/MonumentProducts/Visits/EditarVisita.vue")
    // },
    // Catch-all route for not found pages
    {
      path: '/:catchAll(.*)',
      component: () => import('../views/NotFound.vue'),
    },
  ]
})

router.beforeEach(async (to, from, next ) => {
  const publiPages = [
    '/login',
    '/password-reset',
    '/passwordReset',
    '/login-invitados',
    '/login-prestadores'
  ]
  const authRequired = !publiPages.includes(to.path)
  const authStore = useAuthStore()
  const userStore = useUserStore()

  const tokenByAnotherWeb = to.query.token as string ;
  const usernameByAnotherWeb = to.query.username as string;

  if( tokenByAnotherWeb && usernameByAnotherWeb ){
    authStore.$state.authToken = `"${tokenByAnotherWeb}"`;
    authStore.$state.username = usernameByAnotherWeb;
    localStorage.setItem('authToken', `"${tokenByAnotherWeb}"`);
    localStorage.setItem('username', usernameByAnotherWeb);
  }

  if( authRequired && !authStore.authToken && !userStore.user.accessToken ) {
    // AuthenticatorAttestationResponse.returnUrl = to.fullPath;
    next( { path: '/login'} )
  }
  else if( authStore.authToken && authRequired ){
    try{
      await authStore.checkIfStillValid();
      next();
    }
    catch( err ){
      const errorResponse = err as AxiosError;
      console.log( err );
      if( errorResponse.response?.status === 401 ){
        authStore.logout( false );
        next( { path: '/login' } );
      }
    }
  }
  else{
    next();
  }
})


export default router
