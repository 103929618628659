import { ref, computed, type Ref } from 'vue'
import { defineStore } from 'pinia'
import authService from '@/services/auth.service'
import PrestadoresServices from '@/services/prestadores.service'
import { useLocalStorage } from '@vueuse/core'
import { type LoginPrestadoresAdminResponse } from '@/services/prestadores.service'

export const useUserStore = defineStore('user', {
  state: () => ({
    token: null,
    user: useLocalStorage('user', {
      accessToken: null,
      modulos: [],
      username: null,
      prestadorId: null,
      prestadorNombre: null,
      prestadorOldId: null,
    }) as Ref<LoginPrestadoresAdminResponse>
  }),

  getters: {
    userToken: () => localStorage.getItem('userToken'),
    isLoggedIn: (state) => {
      if (localStorage.getItem('userToken') || state.user.accessToken) {
        return true
      } else {
        return false
      }
    },
    getUsername() {
      if (localStorage.getItem('username')) {
        return localStorage.getItem('username')
      } else {
        return ''
      }
    },
    isAdmin() {
      // TODO: change to set the right admin permission
      return true
    },
    getUserData(state) {
      return {
        ...state.user
    }}

  },
  actions: {
    async getModulos(){
        const { data }  = await authService.userModules();
        return data.adminModulos as string[];
        // return result.data;
    },
    async checkIfStillValid(){
        const { data } = await authService.checkIfStillValid();
        return data;
    },
    async logIn(payload: { name: string; password: string }) {
      const { data } = await authService.userLogin(payload.name, payload.password)
      localStorage.setItem('userToken', JSON.stringify(data.accessToken))
      localStorage.setItem('username', payload.name)
      return true
    },
    async logInAsPrestador(data: LoginPrestadoresAdminResponse) {
      // const { data } = await PrestadoresServices.LoginPrestadoresAdmin({ ...payload })
      this.user = { ...data }

      localStorage.setItem('authToken', JSON.stringify( data.accessToken ) );
      localStorage.setItem( "username", JSON.stringify(data.username) );
    },
    logout() {
      if (localStorage.getItem('userToken')) {
        localStorage.removeItem('userToken')
      }
      if (localStorage.getItem('username')) {
        localStorage.removeItem('username')
      }

      //Esto igual cambia los valores en el localstorage y en el store "user" con la misma estructura del objeto pero con valores nulos
      this.user = {
        accessToken: null,
        modulos: [],
        username: null,
        prestadorId: null,
        prestadorNombre: null,
        prestadorOldId: null,
      }
    },
    
    async getMonument() {
      // TODO: Add the logic to find the monument
      // const { data } =
      return 'asdfas'
    }
  }
})
