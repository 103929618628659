import http2 from "../http-common";
// import http from "@/http-admin-common";
import { useAuthStore } from "@/stores/auth.store";
class AuthService {

    async userLogin( name: string, password: string ) {
        const result = await http2.post('/auth/loginAdmin', {
            username: name,
            password
        } );
        return result.data;
    }

    async checkIfStillValid(){
        const authStore = useAuthStore();
        const token = authStore.authToken;
        const result = await http2.get( '/auth/check-if-still-valid', {
            headers: {
                'Authorization': `Beared ${token}`
            }
        })

        return result.data;
    }

    userModules(): Promise<any>{
        const authStore = useAuthStore();
        const token = authStore.authToken;
        return http2.get('/me/user-modules', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
}

export default new AuthService();