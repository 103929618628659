import http from '../http-common';
import { useAuthStore } from '@/stores/auth.store';

class MeService{
    async getAuthorizedMonuments(){
        const store = useAuthStore();
        return http.get('/me/authorized-monuments', {
            headers:{
                'Authorization': `Bearer ${store.authToken}`
            }
        })
    }

    async getDefaultMonument(){
        const store = useAuthStore();
        return http.get( `/me/default-monument`, {
            headers: {
                Authorization: `Bearer ${store.authToken}`
            }
        } )
    }

    async getAuthorizedVisits( monumentoId: string ){
        const store = useAuthStore();
        return http.get(`/me/authorized-visits`, {
            params: {
                monumentoId
            },
            headers:{
                'Authorization': `Bearer ${store.authToken}`
            }
        })
    }

    async userModules(){
        const store = useAuthStore();
        return http.get(`/me/user-modules`, {
            headers:{
                Authorization: `Bearer ${store.authToken}`
            }
        })
    }

    async getGuestData(){
        const store = useAuthStore();
        return http.get("/me/guest-data", {
            headers: {
                Authorization: `Bearer ${store.authToken}`
            }
        })
    }

    async getNotificationsPending(){
        const store = useAuthStore();
        return http.get("/me/notify-pending", {
            headers: {
                Authorization: `Bearer ${store.authToken}`
            }
        })
    }
}

export default new MeService();