<template>
    <div class="d-flex">
        <MDBBtn class="mx-auto" color="danger" @click="logout">
            Cerrar sesión
        </MDBBtn>
    </div>
</template>

<script setup lang="ts">
import { MDBBtn } from 'mdb-vue-ui-kit';
import { useUserStore } from '@/stores/user';
import { useAuthStore } from '@/stores/auth.store';
//import { useRouter } from 'vue-router';

const userStore = useUserStore();
const store = useAuthStore();

// const router = useRouter();
// Close user session
function logout(){
    store.logout();
    userStore.logout();
    // await router.push('/login')
}
</script>

<style scoped>

</style>